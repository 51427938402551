@import 'themes-vars';
@import 'plugins/plugins';

/* breadcrumb */
html {
  font-size: 75%;
}
.icon-breadcrumb {
  margin-right: 5px;
}

.link-breadcrumb {
  font-size: 0.75rem;
  display: flex;
  text-decoration: none;
}

.profileButton{
  margin-top: 8px;
  padding: 8px;
}
/* card */
.card-header {
  font-weight: 600 !important;
  font-size: 15px;
}

.apexcharts-legend-marker {
  margin-right: 8px !important;
}

.MuiCard-root {
  &.bg-primary {
    background-color: $primary;
    color: #fff;
    .MuiCardHeader-root {
      background-color: $primary;
      color: #fff;
    }
  }
  &.bg-secondary {
    background-color: $secondary;
    color: #fff;
    .MuiCardHeader-root {
      background-color: $secondary;
      color: #fff;
    }
  }
  &.bg-error {
    background-color: $error;
    color: #fff;
    .MuiCardHeader-root {
      background-color: $error;
      color: #fff;
    }
  }
  &.bg-warning {
    background-color: $warning;
    color: #fff;
    .MuiCardHeader-root {
      background-color: $warning;
      color: #fff;
    }
  }
  &.bg-info {
    background-color: $info;
    color: #fff;
    .MuiCardHeader-root {
      background-color: $info;
      color: #fff;
    }
  }
  &.bg-success {
    background-color: $success;
    color: #fff;
    .MuiCardHeader-root {
      background-color: $success;
      color: #fff;
    }
  }
  &.bg-dark {
    background-color: $dark;
    color: #fff;
    .MuiCardHeader-root {
      background-color: $dark;
      color: #fff;
    }
  }
  &.outline-primary {
    border: 1px solid + $primary;
    .MuiCardHeader-root {
      border-bottom: 1px solid + $primary;
    }
  }
  &.outline-secondary {
    border: 1px solid $secondary;
    .MuiCardHeader-root {
      border-bottom: 1px solid + $secondary;
    }
  }
  &.outline-error {
    border: 1px solid $error;
    .MuiCardHeader-root {
      border-bottom: 1px solid + $error;
    }
  }
  &.outline-warning {
    border: 1px solid $warning;
    .MuiCardHeader-root {
      border-bottom: 1px solid + $warning;
    }
  }
  &.outline-info {
    border: 1px solid $info;
    .MuiCardHeader-root {
      border-bottom: 1px solid + $info;
    }
  }
  &.outline-success {
    border: 1px solid $success;
    .MuiCardHeader-root {
      border-bottom: 1px solid + $success;
    }
  }
  &.outline-dark {
    border: 1px solid $dark;
    .MuiCardHeader-root {
      border-bottom: 1px solid + $dark;
    }
  }
}

.f-right {
  float: right;
}
.p-0 {
  padding: 0 !important;
}
.p-20 {
  padding: 20px !important;
}

.responsive-table-card {
  .MuiTableCell-root {
    white-space: unset;
  }
}
.apexcharts-legend-text {
  padding-right: 4px;
}
.MuiDataGrid-columnHeaders {
  overflow: scroll;
}
.tss-1opebqo-MUIDataTable-caption {
  display: none;
}

.css-7blk3r-MuiButtonBase-root-MuiButton-root {
  color: $textDarkDark !important;
}

.MuiMenuItem-root.Mui-selected {
  background: rgba(51, 102, 255, 0.3);
}



// Fixes the Selector on Grid
.fixSelector {
  padding: 18px 0 0 18px !important;
}

.textFieldUnderTitle {
  padding: 10px 0 0 24px !important;
}

.grid-container {
  display: flex;
  justify-content: space-between;
}

.grid-container > .MuiGrid-item {
  flex: 1;
  margin-left: 0
}

.pdf-link {
  text-decoration: none;
  color: white
}

.ferris-wheel-container {
  overflow: hidden; /* Hide other items */
  position: relative;
}

.ferris-wheel-item {
  display: flex; /* Flexbox to align items in a row */
  align-items: center; /* Vertically center the items */
  opacity: 1; /* Default opacity */
  transition: opacity 0.5s ease, transform 0.5s ease;
  height: 100%;
}

.ferris-wheel-item.fade {
  opacity: 0; /* Fade out the previous item */
}

.ferris-wheel-item.fade-in {
  opacity: 1; /* Fade in the current item */
}