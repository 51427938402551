.actions {
    display: flex;
    justify-content: space-between;
}

.edit,
.delete {
    &:hover{
        cursor: pointer;
    }
}